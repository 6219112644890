<template>
    <div class="news">
        <nav-bar class="nav-bar" id="titleArea" ref="nav"></nav-bar>
        <div class="news-content" id="middleArea">
            <div class="teaCourse">
                <div class="top">
                    <h3>名师名课</h3>
                </div>
                <div class="box">
                    <router-link class="row" v-for="(item, index) in pagelist.list" :key="index" :to="{
                        name: '/videoDetail',
                        params: {
                            'id': item.id
                        }
                    }">
                        <div class="courseImg">
                            <img :src="item.imgUrl" alt="" class="t_img">
                            <div class="videoBox"></div>
                            <img src="../../assets/img/news/video_button.png" alt="" class="f_img">
                        </div>
                        <p>{{ item.title }}</p>
                    </router-link>
                </div>
            </div>
            <div class="flex-acjc">
                <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page.sync="currentPage" :page-size=this.pagesize layout="prev, pager, next, jumper"
                    :total=this.total>
                </el-pagination>
            </div>
        </div>
        <bottom-bar class="home-bottom" />
    </div>
</template>
<script>

import { Message, MessageBox } from 'element-ui'  //导入element-ui组件库
import { PageTeacherVideoList } from '@/api/news'
import { getInfo } from '@/api/login'

import NavBar from '../../components/content/navBar/NavBar'
import BottomBar from '../../components/content/bottomBar/BottomBar'
import { getToken, setToken, removeToken } from '@/utils/cookies.js'

export default {
    name: 'News',
    components: {
        NavBar,
        BottomBar
    },
    data() {
        return {
            currentPage: 1,//初始页      
            pagesize: 12,//每页的数据            
            users: {},//用户信息
            total: 0,//总数
            listQuery: {
                pageIndex: this.currentPage,
                pageSize: this.pagesize,
                newsTypeId: 2
            },
            pagelist: [],
        }
    },
    created() {
        //名师名课列表
        this.onPageTeacherVideoList();
    },
    methods: {
        //名师名课列表
        onPageTeacherVideoList() {
            this.listLoading = true;
            this.listQuery.pageIndex = this.currentPage,
                this.listQuery.pageSize = this.pagesize,
                PageTeacherVideoList(this.listQuery).then(response => {
                    if (response) {
                        if (response.status == 200) {
                            this.pagelist = response.data;
                            this.total = response.data.total;
                        } else {
                            MessageBox.alert(response.message, "错误", {
                                confirmButtonText: '确定',
                                type: 'error'
                            })
                        }
                        setTimeout(() => {
                            this.listLoading = false
                        }, 1.5 * 1000)
                    }
                })
        },
        //各地教研点击
        details(id) {
            this.$router.push({
                name: '/videoDetail',
                params: {
                    "id": id
                }
            });
        },
        //初始页currentPage、初始每页数据数pagesize和数据data
        handleSizeChange(size) {
            this.pagesize = size;
            this.onPageTeachNewList();
        },
        //点击第几页
        handleCurrentChange(currentPage) {
            this.currentPage = currentPage;
            this.onPageTeachNewList();
        }
    },
    mounted() {
        this.$refs.nav.mdd = 3;
        localStorage.setItem("mdd", 3);
        const titleHeight = document.getElementById('titleArea').clientHeight;
        const middleArea = document.getElementById('middleArea');
        const mheight = document.documentElement.clientHeight;
        middleArea.style.minHeight = (Number(mheight) - (Number(titleHeight) + 50)) + 'px';
    },
}
</script>
<style scoped lang="less">
.news {
    min-width: 1000px;

    .nav-bar {
        .bar-icon {
            border-radius: 50%;
            vertical-align: -4px;
            margin-right: 7px;
            width: 1.05rem;
            height: 1.05rem;
        }
    }

    .flex-sbac {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .news-content {
        padding-left: 9.1rem;
        padding-right: 9.1rem;
        margin-top: 1.1rem;
        padding-bottom: 1.8rem;

        .teaCourse {
            margin-top: 20px;
            background-color: #fff;
            margin-bottom: 30px;

            .top {
                height: 50px;
                line-height: 50px;
                border-bottom: 1px solid #ececec;
                box-sizing: border-box;

                h3 {
                    font-size: 18px;
                    color: #333;
                    font-weight: normal;
                    line-height: 50px;
                    display: inline-block;
                    margin: 0;
                    padding-left: 10px;
                    padding-right: 10px;
                    border-bottom: 1px solid #469bd7;
                    box-sizing: border-box;
                    position: relative;
                    top: -1px;
                }
            }

            .box {
                padding-top: 20px;
                display: flex;
                flex-wrap: wrap;
                padding-left: 10px;
                padding-right: 10px;

                .row {
                    padding-left: 10px;
                    padding-right: 10px;
                    padding-bottom: 5px;
                    width: 25%;
                    display: block;

                    &:nth-child(4n) {
                        margin-right: 0px;
                    }

                    .courseImg {
                        height: 155px;
                        position: relative;
                        overflow: hidden;
                        border-radius: 3px;

                        .t_img {
                            width: 100%;
                            height: 100%;
                            border-radius: 3px;
                            transition: transform .5s;
                        }

                        .videoBox {
                            position: absolute;
                            left: 0;
                            top: 0px;
                            bottom: 0;
                            right: 0;
                            background: rgba(0, 0, 0, 0.1);
                            border-radius: 3px;
                        }

                        .f_img {
                            position: absolute;
                            top: 20px;
                            left: 20px;
                            display: inline-block;
                            width: 48px;
                            height: 48px;
                        }
                    }

                    p {
                        color: #666;
                        text-overflow: ellipsis;
                        overflow: hidden;
                        white-space: nowrap;
                        margin-top: 10px;
                        font-size: 14px;
                    }

                    &:hover {
                        .t_img {
                            transform: scale(1.1);
                        }

                        p {
                            color: #469bd7;
                        }
                    }
                }
            }
        }
    }
}
</style>