var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"news"},[_c('nav-bar',{ref:"nav",staticClass:"nav-bar",attrs:{"id":"titleArea"}}),_c('div',{staticClass:"news-content",attrs:{"id":"middleArea"}},[_c('div',{staticClass:"teaCourse"},[_vm._m(0),_c('div',{staticClass:"box"},_vm._l((_vm.pagelist.list),function(item,index){return _c('router-link',{key:index,staticClass:"row",attrs:{"to":{
                    name: '/videoDetail',
                    params: {
                        'id': item.id
                    }
                }}},[_c('div',{staticClass:"courseImg"},[_c('img',{staticClass:"t_img",attrs:{"src":item.imgUrl,"alt":""}}),_c('div',{staticClass:"videoBox"}),_c('img',{staticClass:"f_img",attrs:{"src":require("../../assets/img/news/video_button.png"),"alt":""}})]),_c('p',[_vm._v(_vm._s(item.title))])])}),1)]),_c('div',{staticClass:"flex-acjc"},[_c('el-pagination',{attrs:{"background":"","current-page":_vm.currentPage,"page-size":this.pagesize,"layout":"prev, pager, next, jumper","total":this.total},on:{"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange,"update:currentPage":function($event){_vm.currentPage=$event},"update:current-page":function($event){_vm.currentPage=$event}}})],1)]),_c('bottom-bar',{staticClass:"home-bottom"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"top"},[_c('h3',[_vm._v("名师名课")])])
}]

export { render, staticRenderFns }